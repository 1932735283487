import { Link } from 'react-router-dom';
import { useState } from 'react';

import CustomButton from '../Button/Button';

import './Cookies.scss';

const Cookies = () => {

    const [hide, setHide] = useState(localStorage.getItem('hide'));

    const agreeCookies = () => {
        localStorage.setItem('hide', true);
        setHide('true')
    }

    return (
        <>
            {hide === 'true'? 
                null
                :
                <div className="cookies">
                    <h3>Ми використовуємо файли cookie 🍪</h3>
                    <div className="link">
                        <a href="https://2gdpr.com/cookies"> Детальніше</a>
                    </div>
                    <div className="link">
                        <Link to='/policy'>Політика конфіденційності</Link>
                    </div>
                    <CustomButton color={'#375a6a'} action={agreeCookies} text={'Дозволити'}/>
                </div>

            }
        </>
    )
}

export default Cookies;