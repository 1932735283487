import {ReactComponent as TG} from '../../img/icons/telegram.svg';

import './TelegramButton.scss';

const TelegramButton = () => {

    return(
        <a className='tg' href="https://t.me/HvojaStore">

           
                <TG/>
                <p style={{width: '70%', textAlign:'right', margin:0}}>
                    Проблеми з замовленням?
                </p>

        </a>
    )
}

export default TelegramButton;