import { useState } from "react";
import useHvojaService from "../../services/HvojaService";
import { useEffect } from "react";

import Modal from 'react-bootstrap/Modal';
import OrderForm from "../Forms/OrderForm";
import CustomButton from "../Button/Button";
import { Spinner } from 'react-bootstrap';
import SingleBagComponent from '../SingleBagComponent/SingleBagComponent';
import {ReactComponent as EmptyBag} from '../../img/icons/bag_empty.svg'

import './BagComponent.scss';


const BagComponent = ({bagData, setBagData, order, setOrder, showBag, setShowBag, setBagLength, totalPrice, setTotalPrice}) => {

    const { loading, error,  getForBag} = useHvojaService();


   const onLoaded = (data) => {
        setBagData(data)
    }


    const getBagData = ()=> { 
        if(showBag && localStorage.getItem('goods')) {
            getForBag([...JSON.parse(localStorage.getItem('goods'))].map((item=>Object.keys(item)[0].toString()))).then(onLoaded);
        }
    }
    useEffect(()=> getBagData(), []);

    useEffect(()=> {
            setBagLength(localStorage.getItem('goods')?  [...JSON.parse(localStorage.getItem('goods'))].length : 0);
    }, [bagData])


    const handleClose = () => setShowBag(false);


    const createOrder = () => {
        setOrder(true);
        // localStorage.removeItem('goods');
    }

    const parseStorage = () => {
        return [...JSON.parse(localStorage.getItem('goods'))]
    }

    const getStorageData = (guid) => {
        const filtered = parseStorage().filter((item)=>item[guid]);
        const rest = parseStorage().filter((item)=>!item[guid]);
        return {filtered, rest};
    }
    // const mapPrices = () => {
    //     // const newSm = bagData?.map((item, i) => {
    //     //     const res = +Object.values([...JSON.parse(localStorage.getItem('goods'))][0])[0] * +(item?.discount? item.discountPrice : item.price)    

    //     //     const newData = totalPrice.map((el, n) => {
    //     //         if(Object.keys(el)[0] === item.guid)
    //     //            return Object.assign({}, el, {[`${Object.keys(el)[0]}`]:res})
    //     //         return el
    //     //     });
    //     setTotalPrice( totalPrice.map((val, n) => {
    //         const filtered = bagData.filter(j => j?.guid === Object.keys(val)[0])[0];
    //         console.log( bagData)
    //         // val = filtered.discount? filtered.discountPrice : filtered.price;
    //         // return Object.assign({}, val)
    //     })  )
    // }

    // useEffect(()=> {mapPrices()}, [])




   

    return (
        <>
         <Modal show={showBag} onHide={handleClose}>
         
            <Modal.Body >


                <div style={{margin: '0 auto'}}>

                
                    <Modal.Header className="border border-0" closeButton>
                        <h3 className="title">{'кошик'}</h3>
                    </Modal.Header>

                    {
                        !localStorage.getItem('goods') || [...JSON.parse(localStorage.getItem('goods'))].length === 0 ? 
                        <div style={{maxWidth: '270px', margin: '0 auto'}}>
                            <EmptyBag style={{maxWidth: '100%', height: '270px'}}/>
                        </div>
                        :
                        loading? 
                            <Spinner animation="border" role="status" >
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                            :
                            <>
                                <div className="bag_list">
                                    {bagData?.map((item, i) => {
                                        
                                        return <SingleBagComponent totalPrice={totalPrice} setTotalPrice={setTotalPrice} bagData={bagData} setBagData={setBagData} getStorageData={getStorageData} item={item} key={i} loading={loading} error={error}/>
                                    })  } 
                                </div>
                                <div className="summary" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <div style={{fontSize:'20px', fontWeight: 'bolder'}}>
                                        {totalPrice.map(i => {
                                            return Object.values(i)
                                        }).reduce((a, b) => +a + +b)} ₴
                                    </div>
                                    <CustomButton action={()=>createOrder()} text="замовити"/>

                                </div>
                            </>   
                    }
                </div>
            </Modal.Body>
        </Modal>
        {/* <OrderForm showBag={showBag} formType={'order'} setBagLength={setBagLength} setShowBag={setShowBag} comment={true} title='Оформленя замовлення' setOrder={setOrder} order={order} fullInfo={localStorage.getItem('goods')} id={bagData.map(item=>item.guid)} descr={bagData.map(item=>item.descr)}/> */}
        </>
    )
}

export default BagComponent;