import { useState, useEffect } from 'react';
import {Helmet} from "react-helmet-async";
import useHvojaService from '../../../services/HvojaService';

import { Spinner } from "react-bootstrap";
import ProductCard from '../../ProductCard/ProductCard';
import Error from '../../Error/Error';

import './Promotion.scss';

const PromotionSpecial = (props) => {
//   const [days, setDays] = useState('00');
//   const [hours, setHours] = useState('00');
//   const [minutes, setMinutes] = useState('00');
//   const [seconds, setSeconds] = useState('00');

//   const deadline = "January, 30, 2023";

//     function checkNum(num) {

//         let res = num;

//         if (num >= 0 && num < 10) {
//             res = '0' + String(num);

//             return res
//         } else {
//             return num
//         }

//     }

//   const getTime = () => {
//     const time = Date.parse(deadline) - Date.now();

//     setDays(checkNum(Math.floor(time / (1000 * 60 * 60 * 24))));
//     setHours(checkNum(Math.floor((time / (1000 * 60 * 60)) % 24)));
//     setMinutes(checkNum(Math.floor((time / 1000 / 60) % 60)));
//     setSeconds(checkNum(Math.floor((time / 1000) % 60)));
//   };

//   useEffect(() => {
//     const interval = setInterval(() => getTime(deadline), 1000);

//     return () => clearInterval(interval);
//   }, []);

const {loading, error, getAllProducts} = useHvojaService();


  const [data, setData] = useState([]);

  const onLoaded = (data) => {
      setData(data.sort((a,b) => a.id - b.id).filter(item => item.discount === true));
  }

  const onRequest = () => {
    getAllProducts().then(onLoaded);
  }

  useEffect(() => {onRequest()}, []);


    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Promotions"
                />
                <meta name="keywords" content="меблі, дерево, крісла, підставки, магазин меблів, каталог, столи, мʼякі крісла, аксесуари, крісло, мʼяке крісло, підставка, тримач, стіл, маленький стіл, журнальний столик, журнальні столи, журнальні столики, журнальний стіл, акції, знижки, акційни товари, hvoja, Hvoja"/>
                <meta property="og:title" content="Hvoja - інтернет магазин меблів" />
                <meta property="og:description" content="Небачені знижки в hvoja! Даруємо затишок та комфорт." />
                <meta property="og:image" content='../../../img/backgrounds/main-page-bckg.png' />
                <meta property="og:url" content="https://hvoja.store/" />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Hvoja - інтернет магазин меблів" />
                <meta name="twitter:description" content="Небачені знижки в hvoja! Даруємо затишок та комфорт." />
                <meta name="twitter:image" content="../../../img/backgrounds/main-page-bckg.png" />
                <meta name="robots" content="index, follow"/>

                <title>Спеціальні пропозиції</title>
            </Helmet>

            <div className="container">

                {/* <div className="promo_card">
                    <div className="promo_header_main">
                        <img className='promo_img_left' src={require('../../../img/icons/xmas_omela.png')} alt="" />
                        <h2 className='promo_title'>НОВОРІЧНІ ЗНИЖКИ </h2>
                        <img className='promo_img_right' src={require('../../../img/icons/xmas_omela.png')} alt="" />
                    </div>
                    
                    <div className="promo_wrapper">
                        <div className="promo_text">
                            <h2 className='promo_header'>до -50%</h2>
                            <p className='promo-descr'>затишок та комфорт у подарунок</p>
                        </div>

                        <div className="promo_timer">
                            <div>
                                <div className="timer_container">{days}</div>
                                <div className='timer_descr'  style={{textAlign: 'center'}}>дні</div>
                            </div>
                            <div>
                                <div className="timer_container">{hours}</div>
                                <div className='timer_descr'  style={{textAlign: 'center'}}>години</div>
                            </div>
                            <div>
                                <div className="timer_container">{minutes}</div>  
                                <div className='timer_descr'  style={{textAlign: 'center'}}>хвилини</div>
                            </div>
                            <div>
                                <div className="timer_container">{seconds}</div> 
                                <div  className='timer_descr' style={{textAlign: 'center'}}>секунди</div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <h2 className='promo_subtitle'>ТОВАРИ ЗА АКЦІЙНОЮ ЦІНОЮ</h2>

                {loading? 

                    <div style={{textAlign: 'center'}}>
                        <Spinner animation="border" role="status" >
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                        : 
    
                    error? <Error/> : 

                    <div className="promo_items">
                        {data.length !== 0?
                        data.map((item, i) => {
                            return (
                                <div key={i}>
                                    <ProductCard setRootId={props.setRootId} item={item} num={i} type={item.urlType}/>
                                </div>
                            )
                        })
                        :
                        <>
                            <h4>На даний момент в нас немає акційних товарів 😢</h4>
                        </>
                        }
                    </div>
                }
                
            </div>
        </>
    )
}

export default PromotionSpecial;