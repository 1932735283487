import { useEffect } from 'react';

import { Spinner } from 'react-bootstrap';
import Counter from '../Counter/Counter';
import {ReactComponent as Trash} from '../../img/icons/trash.svg';

import './SingleBagComponent.scss';

const SingleBagComponent = ({item, loading, getStorageData, setBagData, bagData, setTotalPrice, totalPrice}) => {

    useEffect(()=> {
        if (totalPrice.length !== 0) {

            setTotalPrice(totalPrice.map(i => {
                const bagItem = bagData.filter(j => j?.guid === Object.keys(i)[0])[0]
                const res = +Object.values([...JSON.parse(localStorage.getItem('goods'))].filter(a=> Object.keys(a)[0] === Object.keys(i)[0])[0])[0] * +(bagItem?.discount? bagItem?.discountPrice : bagItem?.price)    
                // console.log(Object.values([...JSON.parse(localStorage.getItem('goods'))].filter(a=> Object.keys(a)[0] === Object.keys(i)[0])[0])[0])
                return {[`${Object.keys(i)[0]}`]: res}
            }))
        }
    }, [])


    const removeItem = () => {
        setBagData(data=>data?.filter(i => i.guid!==item?.guid) )
        localStorage.setItem('goods', JSON.stringify(JSON.parse(localStorage.getItem('goods')).filter((i)=>!i[item?.guid])))
        setTotalPrice(data=>data?.filter(i => Object.keys(i)[0] !== item?.guid) )
    }

    return (
        <div className='bag_item'>
            {loading? 
                <Spinner animation="border" role="status" >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                :
                <div className="bag_container">
                    <div className="bag_img">
                    {item?.imgArr[0]?.url? <img src={item?.imgArr[0]?.url} alt="обраний товар" className="bag_img" /> : null}
                    </div>

                    <div className="break_xs"></div>

                    <div className="bag_text">
                        <h5>{item?.name}</h5>
                        <p className='bag_info'>{item?.discount? `${item?.discountPrice} ₴` : `${item?.price} ₴`}</p>
                        <p className='bag_info'>{item?.descr}</p>
                    </div>

                    <div className="break"></div>

                    <div className="bag_counter">
                        <Counter totalPrice={totalPrice} setTotalPrice={setTotalPrice} item={item} bagData={bagData} guid={item?.guid}/>
                    </div>

                    <div className="bag_remove">
                        <button onClick={()=>removeItem()} className="remove">
                            <Trash/>
                        </button>
                    </div>
                </div>
            }
        </div>
    )
}

export default SingleBagComponent;