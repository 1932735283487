// import {  useEffect } from 'react';

import Modal from 'react-bootstrap/Modal';
import CustomButton from '../Button/Button';

import './Response.scss';


const Response = ({showRes, setShowRes, result, setShowBag}) => {
    

    // useEffect(()=> {
    //     if (showRes) {
    //         setTimeout(()=> {setShowRes(false); setShowBag(false)}, 3000)
    //     }
    // }, [showRes, setShowRes, setShowBag])

    return (
       <Modal className='order_response' show={showRes} variant="success">
            
            <Modal.Header  className="border border-0" />
            <Modal.Body>
                {
                    result === 'ok'?
                    <>
                        <h3  className="title">Дякуємо за замовлення</h3>
                        <p>Наш менеджер згодом звʼяжеться з вами</p>
                        <div className="order_response__action">
                            <CustomButton color='#adbb5b'  action={()=>setShowRes(false)} text={'Закрити'}/>
                        </div>
                    </>
                    :
                    <>
                        <h3  className="title">{'Упс, щось пішло не так :('}</h3>
                        <p>Ви можете <a href="mailto: hvoja.store@gmail.com">написати нам на почту</a> або  <a href="https://t.me/polyglot18">відправити повідомлення в Telegram</a></p>
                        <div className="order_response__action">
                            <CustomButton color='#adbb5b'  action={()=>setShowRes(false)} text={'Закрити'}/>
                        </div>
                    </>
                }
            </Modal.Body>

       </Modal>
    )
}

export default Response;