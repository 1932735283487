import { useState } from 'react';
import { BlurhashCanvas } from "react-blurhash";

import Carousel from 'react-bootstrap/Carousel';

import './Carusel.scss';


const Slider = ({ item, handleShow}) => {

    const [indexSl, setIndexSl] = useState(0);
    const [imgLoaded, setImgLoaded] = useState(false);

    const handleSelect = (selectedIndex, e) => {
        setIndexSl(selectedIndex);
    };



    return (
        <>
            <Carousel variant="dark" activeIndex={indexSl} onSelect={handleSelect} interval={null}>
                            
                {
                    item?.imgArr? 

                    item?.imgArr?.map((itemMap, i)=> {
                        return (
                            
                            <Carousel.Item className='carusel_item_outer' onClick={handleShow} key={i}>
                                <div className="carusel_item">
                                    <div className="item" style={{display: imgLoaded? 'none' : 'inline'}}>
                                        <BlurhashCanvas style={{width: '100%', height: '270px'}} hash='K6Pj0^jE.A_3t7t7*0o#Dg'/>
                                    </div>
                                    
                                    <div className="item" style={{height: '100%'}}>
                                        <img
                                            onLoad = {()=>setImgLoaded(true)}
                                            className="d-block w-100"
                                            src={itemMap.url}
                                            alt={item?.descr}
                                            />
                                    </div>
                                    
                                </div>
                            </Carousel.Item>
                            
                        )
                    })
                    :
                    <img src={require('../../img/icons/no_img.png')} alt='no_image'/>
                }
               
            
            </Carousel>
        </>
    )
}

export default Slider;