import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { useState} from 'react';
import { Navigate } from 'react-router-dom';
import useHvojaService from './services/HvojaService';

import Header from './components/Header/Header';
import MainPage from './components/pages/MainPage/MainPage';
import CatalogPage from './components/pages/CatalogPage/CatalogPage';
import Contacts from './components/pages/Contacts/Contacts';
import SingleCatalogPage from './components/pages/SingleCatalogPage/SingleCatalogPage';
import SingleProductPage from './components/pages/SingleProductPage/SingleProductPage';
import DialogWindow from './components/DialogWindow/DialogWindow';
import ErrorPage from './components/pages/ErrorPage/ErrorPage';
// import Promotion from './components/pages/Promotion/Promotion';
import PromotionSpecial from './components/pages/Promotion/PromotionSpecial';
import Footer from './components/Footer/Footer';
import Policy from './components/pages/Policy/Policy';
import Cookies from './components/Cookies/Cookies';
import BagComponent from './components/BagComponent/BagComponent';
import OrderForm from './components/Forms/OrderForm';
import TelegramButton from './components/TelegramButton/TelegramButton';


import './App.css';

function App() {

  const [rootType, setRootType] = useState('armchair');
  const [rootId, setRootId] = useState('0');

  const [show, setShow] = useState(false);
  const [showBag, setShowBag] = useState(false);
  const [bagLength, setBagLength] = useState(localStorage.getItem('goods')? [...JSON.parse(localStorage.getItem('goods'))].length : 0);

  const [totalPrice, setTotalPrice] = useState(localStorage.getItem('goods')? [...JSON.parse(localStorage.getItem('goods'))] : []);

  const {getArmchairs, getTables, getAccess, loading, error, getFabricArr, getWoodArr, getAllProducts, getForBag} = useHvojaService();

  const [order, setOrder] = useState(false);
  const [bagData, setBagData] = useState([]);



  return (
    <>


    <Router>
      <DialogWindow show={show} 
                  setShow={setShow} 
                  setRootId={setRootId}
                  rootId={rootId}
                  loading={loading} error={error} getItem={getArmchairs} getFabricArr={getFabricArr} getWoodArr={getWoodArr}
      />  

     {
      showBag? 
      <BagComponent bagData={bagData} setBagData={setBagData} order={order} setOrder={setOrder} totalPrice={totalPrice} setTotalPrice={setTotalPrice} setBagLength={setBagLength} bagLength={bagLength} showBag={showBag} setShowBag={setShowBag} getForBag={getForBag} loading={loading} error={error}/>
        :
        null
      }
      <OrderForm showBag={showBag} formType={'order'} setBagLength={setBagLength} setShowBag={setShowBag} comment={true} title='Оформленя замовлення' setOrder={setOrder} order={order} fullInfo={localStorage.getItem('goods')} id={bagData.map(item=>item.guid)} descr={bagData.map(item=>item.descr)}/>


      <div className="App">

        <div className="">
          <Header setShowBag={setShowBag} setShow={setShow} bagLength={bagLength}/>

          <div className="">
            <Routes>
              <Route path="notfound" element={<ErrorPage />} />
              <Route path="*" element={<Navigate to="/notfound" replace />} />

              <Route path='/' element={<MainPage setShow={setShow}/>}/>
              <Route path='/catalog' element={<CatalogPage setRootType={setRootType}/>}/>
              <Route path='/contacts' element={<Contacts/>}/>

              <Route path='/catalog/armchairs' element={<SingleCatalogPage setShowBag={setShowBag} loading={loading} error={error} getItem={getArmchairs} setRootId={setRootId} type='armchairs' typeName='крісла'/>}/>
              <Route path='/catalog/tables' element={<SingleCatalogPage setShowBag={setShowBag} loading={loading} error={error} getItem={getTables} setRootId={setRootId} type='tables' typeName='столи'/>}/>
              <Route path='/catalog/accessories' element={<SingleCatalogPage setShowBag={setShowBag} loading={loading} error={error} getItem={getAccess} setRootId={setRootId} type='accessories'typeName='аксесуари'/>}/>


              {/* <Route path='/discount' element={<Promotion getItem={getAccess} loading={loading} error={error} setRootId={setRootId} />}/> */}
              <Route path='/promotion' element={<PromotionSpecial  setRootId={setRootId} />}/>

              <Route path={`/catalog/:type/:id`} element={<SingleProductPage totalPrice={totalPrice} setTotalPrice={setTotalPrice} showBag={showBag} setShowBag={setShowBag} getArmchairs={getArmchairs} getTables={getTables} getAccess={getAccess} loading={loading} error={error} rootId={rootId} rootType={rootType} getAllProducts={getAllProducts} getForBag={getForBag} />}/>

              <Route path={'/policy'} element={<Policy/>}/>
            </Routes>
          </div>
        </div>

        <Footer/>
        <Cookies/>
        <TelegramButton/>
      </div>
    </Router>
    </>
  );
}

export default App;
