import { useEffect, useState, useCallback } from "react";
import {Helmet} from "react-helmet-async";

import ProductCard from "../../ProductCard/ProductCard";
import { Spinner } from "react-bootstrap";
// import Sort from '../../Sort/Sort';
// import Filter from '../../Filter/Filter';
import Error from "../../Error/Error";
import ErrorEmptyPage from "../../Error/ErrorEmptyPage";

import './SingleCatalogPage.scss';


const SingleCatalogPage = (props) => {

    const [data, setData] = useState([]);
    const [type, setType] = useState(0);
    const [dataExist, setDataExist] = useState(true)

    // console.log(type)

    const onLoaded =  (data) => {
        setData(data );
    }
 
    const onRequest = async () => {
        await props.getItem().then(d => d.length !== 0? onLoaded(d) : setDataExist(false));
    }


    useEffect(() => {onRequest()}, [props.typeName]);

    const selectPrice = (discount, price, discountPrice) => {
        return discount?  discountPrice : price;
    }

    const sortPrice = (a, b) => {
        switch (type){
            case 0:
                return selectPrice(a.discount, a.price, a.discountPrice) - selectPrice(b.discount, b.price, b.discountPrice)
            case 1:
                return selectPrice(b.discount, b.price, b.discountPrice) - selectPrice(a.discount, a.price, a.discountPrice)
            default:
                return selectPrice(a.discount, a.price, a.discountPrice) - selectPrice(b.discount, b.price, b.discountPrice)
        }
    }


    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Catalog"
                />
                <title>Каталог</title>
                <meta name="robots" content="index, follow"/>
                <meta name="keywords" content="меблі, дерево, крісла, підставки, магазин меблів, каталог, столи, мʼякі крісла, аксесуари, крісло, мʼяке крісло, підставка, тримач, стіл, маленький стіл, журнальний столик, журнальні столи, журнальні столики, журнальний стіл, каталог, hvoja, Hvoja"/>
            </Helmet>

            <div className="single_catalog_container">
                <h3 className="single_catalog_title">{props.typeName}</h3>

                {props.loading? 

                <div style={{textAlign: 'center'}}>
                    <Spinner animation="border" role="status" >
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
                    : 
                    
                    props.error? <Error/> : 

                    !dataExist?

                    <ErrorEmptyPage/>
                    :

                    <div >
                        {/* <Sort setType={setType}/> */}
                        <div className="single_catalog_page">
                            <div className="single_catalog">
                                {data.sort(sortPrice).map((item, i) => {
                                    return <ProductCard setShowBag={props.setShowBag} setRootId={props.setRootId}  key={item.id} item={item} type={props.type} num={i}/>
                                })}
                            </div>
                            {/* <Filter/> */}
                        </div>
                    </div>
                }

            </div>
        </>
    )
}


export default SingleCatalogPage;