import {ReactComponent as Bag} from '../../img/icons/bag.svg';

import { Badge } from 'react-bootstrap';

import './ShopingBag.scss';

const ShoppingBag = ({setShowBag, bagLength}) => {

    return (
        <div className='bag' >
            <button style={{display: 'block'}} onClick={()=>setShowBag(true)} >
                <Bag className='icon'/>
                <div className="count_num">
                    <Badge pill bg="secondary">
                        {bagLength}
                    </Badge>
                </div>
            </button>
        </div>
    )
}

export default ShoppingBag;