import { useState, useCallback } from "react";
import axios from 'axios';


export const useHttp = () => {
    const apiKeyNew = process.env.REACT_APP_AIRTABLE_API_KEY;
    const api = axios.create({
    baseURL: 'https://api.airtable.com/v0',
    headers: {
        Authorization: `Bearer ${apiKeyNew}`
    }
    });

    

    // const apiKey = process.env.REACT_APP_AIRTABLE_API_KEY;

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const request = useCallback(async (table) => {

        setLoading(true);        

        try{
            const response = await api.get(`appwJpTH0iQwh6Znk/${table}`)

            if(!response.status) {
                throw new Error (`Could not fetch , status: ${response.status}`)
            }

            const data = await response.data.records;

            setLoading(false);
            return(data);
            

        } catch (e) {
            setLoading(false);
            setError(e.message);
            throw e;
        }

    }, [apiKeyNew]);

    const clearError = useCallback(() => {
        setError(null);
    }, []);

    return {loading, request, error, clearError}

}

