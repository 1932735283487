import { Link } from "react-router-dom";
import { useState } from "react";

import Slider from "../Carusel/Carusel";
import CustomButton from "../Button/Button";
import OrderForm from "../Forms/OrderForm";
import Delivery from "../Delivery/Delivery";

import './ProductCard.scss';

const ProductCard = ({item, type, setRootId, num, img, setShowBag}) => {

    const [order, setOrder] = useState(false);

    const outStock = item.outStock? 'outStock' : null;


    return (
        
        <>
            <div className={`card_link ${outStock}`}>
                <div className="card_custom">
                    {item.delivery? 
                    <Delivery/>
                    :
                    null
                    }
                    <div className="card_img">
                        <Slider item={item}  />
                    </div>
                    <div className="card_data">
                        <Link  onClick={()=>setRootId(num)} to={`/catalog/${type}/${item.id}`}>
                            <div className="card_dataText">
                                <h4 className="card_title">{item.name}</h4>
                                {item.discount? 
                                    <>
                                        <p className="card_text card_price card_price_old"><i>{`${item.price} ₴`}</i></p>
                                        <p className="card_text card_price card_price_new"><b>{`${item.discountPrice} ₴`}</b></p>
                                    </>
                                    : 
                                    <p className="card_text card_price"><i>{`${item.price} ₴`}</i></p>
                                }
                                
                                <p className="card_text card_descr">{item.descr}</p>
                            </div>
                        </Link>
                        <div style={{margin: '0 auto'}} className="card_link_details">
                            <Link  onClick={()=>setRootId(num)} to={`/catalog/${type}/${item.id}`}> детальніше →</Link>
                        </div>
                        
                        <div className="card_navigation">
                            {
                                !item.outStock?
                                 <CustomButton action={()=>setOrder(true)} text="замовити в один клік" color='#adbb5b' height={'fit-content'} padding={10}/>
                                 :
                                 <div className="outStock_icon">немає в наявності</div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <OrderForm setShowBag={setShowBag} comment={false} title='Оформленя замовлення' setOrder={setOrder} order={order} id={item.id} descr={item.descr}/>
        </>
    )
}

export default ProductCard;