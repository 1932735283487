
import './Delivery.scss';
const Delivery = () => {
    return (
        <>
        <div className="delivery">
            <div className="delivery_wrapper">
                <img src={require('../../img/icons/nova_poshta.png')} alt="іконка доставки" />
            </div>
            <div className='delivery_details'>Безкоштовна доставка Новою Поштою</div>
        </div>
        </>
    )
}

export default Delivery;