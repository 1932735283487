import './Smartphone.scss';

const Smartphone = (props) => {
    return (
        <>
            <div className="smart">
                <div className="top"></div>
                <div className="smart_children">
                    {props.children}
                </div>
            </div>
        </>
    )
}

export default Smartphone;