import { Link } from 'react-router-dom';
import {ReactComponent as Phone} from '../../img/icons/phone.svg';
import {ReactComponent as Envelope} from '../../img/icons/envelope.svg';
import {ReactComponent as Telegram} from '../../img/icons/telegram.svg';
import {ReactComponent as Logo} from '../../img/icons/hvoja-logo-icon.svg';

import './Footer.scss';

const Footer = () => {
    return (
        <>
            <div className="footer">
                <div className="links footer_item">
                    <Link to='/'>Головна сторінка</Link>
                    <Link to='/catalog'>Каталог</Link>
                    <Link to='/catalog/armchairs'>Крісла</Link>
                    <Link to='/catalog/tables'>Столи</Link>
                    <Link to='/catalog/accessoires'>Аксесуари</Link>
                    <Link to='/contacts'>Контакти</Link>
                    <Link to='/promotion'>Акції</Link>
                </div>

                <div className="footer_logo footer_item">
                    <div className="footer_logo_img">
                        <Logo/>
                    </div>
                    <p>hvoja online store 2022-2023</p>
                </div>

                <div className="contacts footer_item">
                <div className="contacts_card">
                        <div className="card_row">
                            <div className="card_pair">
                                <Phone/>
                            </div>
                            <div className="card_data">
                                <a href="tel: +380954009359" className="test">+380 95 400 9359</a>
                            </div>
                        </div>

                        <div className="card_row">
                            <div className="card_pair">
                                <Envelope/>
                            </div>
                            <div className="card_data">
                                <a href="mailto: hvoja.store@gmail.com" className="test">hvoja.store@gmail.com</a>
                            </div>
                        </div>

                        <div className="card_row">
                            <div className="card_pair">
                                <Telegram/>
                            </div>
                            <div className="card_data">
                                <a href="https://t.me/HvojaStore" className="test">написати в Telegram</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;