import {ReactComponent as Facebook} from '../../img/icons/facebook.svg';
import {ReactComponent as Pinterest} from '../../img/icons/pinterest.svg';

import ShoppingBag from '../ShopingBag/ShopingBag';

import './SocialMedia.scss';


const SocialMedia = ({setShowBag, bagLength}) => {
    return (
        <div className="social">
            <div>
                <a target="_blank" rel="noreferrer" href='https://www.facebook.com/profile.php?id=100089637306635'>
                    <Facebook className='icon'/>
                </a>
            </div>
            <div>
                <a target="_blank" rel="noreferrer" href='https://www.pinterest.com/hvojamedia/'>
                    <Pinterest className='icon'/>
                </a>
            </div>
            <div className="">  
                <ShoppingBag bagLength={bagLength} setShowBag={setShowBag}/>
            </div>
        </div>
    )
}

export default SocialMedia;