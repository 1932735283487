import { useState , useEffect} from 'react';

import './Counter.scss';

const Counter = ({guid, bagData, item, setTotalPrice, totalPrice}) => {
    const parseStorage = () => {
        return [...JSON.parse(localStorage.getItem('goods'))]
    }

    const getStorageData = () => {
        const filtered = parseStorage().filter((i)=>i[guid]);
        const rest = parseStorage().filter((i)=>!i[guid]);
        return {filtered, rest};
    }

    const {filtered, rest} = getStorageData();

    const [count, setCount] = useState(filtered[0][guid]);

    const setCountToStorage = (num) => {
        filtered[0][guid] = num;
        localStorage.setItem('goods', JSON.stringify([...filtered, ...rest]))
        // console.log(...filtered)
    }


    const changeCount = (sign) => {
        if(count>=1 && sign === '+') {
            setCount((count)=>count+1);
        }
        else if (count>=2 && sign === '-') {
            setCount((count)=>count-1);
        }
     }

     const changePrice = (num) => {
        if (totalPrice.length !== 0) {

            const restPrice = totalPrice.filter(i=>Object.keys(i)[0] !== item?.guid)

            const res = +num * +(item?.discount? item.discountPrice : item.price)    
            
            setTotalPrice([...restPrice, {[`${guid}`]: res}])
        }
     }

     const changeStorageCount = (num) => {
        setCountToStorage(num)
        setCount(num) 
        changePrice(num)
     }

     useEffect(()=>{changeStorageCount(count)}, [count])
     useEffect(()=> {setCount(filtered[0][guid])}, [bagData])


    return (
        <>
            <div className="counter">
                <button className='counter_button' onClick={()=> changeCount('-')}>-</button>
                <span>{count}</span>
                <button className='counter_button' onClick={()=> changeCount('+')}>+</button>  
            </div>
        </>
    )
}

export default Counter;

