import { useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet-async";
import { useState } from 'react';

import CustomButton from '../../Button/Button';
import Proposal from '../../Donate/Proposal';
import Smartphone from '../../Smartphone/Smartphone';
import {ReactComponent as Telegram} from '../../../img/icons/telegram.svg';
import {ReactComponent as Whats} from '../../../img/icons/whatsapp.svg';
import {ReactComponent as Viber} from '../../../img/icons/viber.svg';
import OrderForm from "../../Forms/OrderForm";

import './MainPage.scss';

const MainPage = ({setShow}) => {

    const navigate = useNavigate();

    const actionCatalog = () => {
        navigate('/catalog');
    }

    const actionConstructor = () => {
        setShow(true)
    }

    const toLink = (link) => {
        window.location.href = link;
      }

    const [order, setOrder] = useState(false)
    // viber://chat?number=+380971111111

    return (
        <>

            <Helmet>
                <meta
                    name="description"
                    content="Hvoja - інтернет магазин меблів та аксесуарів з дерева. Даруємо затишок та комфорт. Використовуємо натуральні матеріали."
                />
                <meta name="keywords" content="меблі, дерево, крісла, підставки, магазин меблів, столи, мʼякі крісла, аксесуари, крісло, мʼяке крісло, підставка, тримач, стіл, маленький стіл, журнальний столик, журнальні столи, журнальні столики, журнальний стіл, hvoja, Hvoja"/>
                <title>Магазин меблів з дерева hvoja: стильні та натуральні вироби для вашого інтер'єру</title>
                <meta property="og:title" content="Hvoja - інтернет магазин меблів" />
                <meta property="og:description" content="Даруємо затишок та комфорт." />
                <meta property="og:image" content='../../../img/backgrounds/main-page-bckg.png' />
                <meta property="og:url" content="https://hvoja.store/" />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Hvoja - інтернет магазин меблів" />
                <meta name="twitter:description" content="Даруємо затишок та комфорт." />
                <meta name="twitter:image" content="../../../img/backgrounds/main-page-bckg.png" />
                <meta name="robots" content="index, follow"/>

            </Helmet>

            <div className="main_page">

                <div className="main_section">
                    <Proposal/>
                    <div className="headers">
                        <h1>
                            hvoja
                        </h1>
                        <h2>
                            дерев'яні меблі та аксесуари
                        </h2>
                    </div>

                    <div className="buttons">
                        <CustomButton text='каталог' action={actionCatalog}/>
                        <CustomButton text='конструктор' action={actionConstructor}/>
                    </div>
                </div>

                <div className="about_us_section">
                    <div className="about_us_divider">
                        <hr className="zag"/>
                    </div>
                    <h2 className='subtitle'>Про нас</h2>
                    <div className="about_us_container">
                        <div className="img_container">
                            <img src={require('../../../img/backgrounds/team-bckg.png')} alt="team" />
                        </div>

                        <p className='text_container'>
                            <b>Вітаємо в інтернет магазині Hvoja!</b>
                            <br/><br/>
                            Пропонуємо асортимент меблів та аксесуарів <b>з натуральної деревини</b>. Наш каталог постійно доповнюється новими позиціями, <b>тож не пропустіть нові товари!</b>
                            <br/><br/>
                            
                            <b>Ми - команда професіоналів</b>, що складається зі столярів, креативних дизайнерів, розробників, менеджерів. Кожного дня ми працюємо задля<b> Вашого комфорту та затишку.</b>
                            <br/><br/>

                            Купуючи у нас, Ви також підтримуєте Українську армію, адже<b> 5% від покупки ми відправляємо на ЗСУ.</b>
                        </p>
                    </div>
                </div>

                <div className="contact_us_section">
                    <h2 className='subtitle'>Виникли питання?</h2>
                    <div className="contact_us_container">

                        <OrderForm formType={'request'} comment={true} title='Напишіть нам' setOrder={setOrder} order={order} id={0} descr={'User request'}/>
                        
                        <div className="contact_us_text">
                            <p >
                                Ви можете звʼязатися з нами через зручний для Вас месенджер!
                                <br/><br/>
                                Або заповнити форму зворотнього звʼязку
                            </p>

                            <div className="contact_arrow">
                                <img src={require('../../../img/icons/arrow.png')} alt="arrow" />
                            </div>
                        </div>
                        

                        <Smartphone>
                            <div className="logos">
                                <Telegram  className='telegram' onClick={()=>toLink("https://t.me/polyglot18")}/>
                                <Whats className='whats' onClick={()=>toLink("https://wa.link/643fi3")}/>
                                <Viber className='viber small_screen' onClick={()=>toLink("https://msng.link/o/?380954009359=vi")}/>
                            </div>
                            <CustomButton text='форма' action={()=> setOrder(true)} color={'#62744b'}/>
                        </Smartphone>
                        
                    </div>
                </div>

            </div>
        </>
    )
}

export default MainPage;