import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Helmet } from 'react-helmet-async';
import useHvojaService from '../../../services/HvojaService';

import CustomButton from "../../Button/Button";
import Modal from 'react-bootstrap/Modal';
import Slider from "../../Carusel/Carusel";
import { Spinner } from "react-bootstrap";
import Error from "../../Error/Error";
import Delivery from "../../Delivery/Delivery";
import ErrorNoItem from "../../Error/ErrorNoItem";

import './SingleProductPage.scss';

const SingleProductPage = ({rootType, rootId, setShowBag, setTotalPrice, totalPrice}) => {

    const {getArmchairs, getTables, getAccess, loading, error} = useHvojaService();


    const {type, id} = useParams();
    const navigate = useNavigate();

    const [show, setShow] = useState(false);

    const [data, setData] = useState([]);




    const onLoaded = (data) => {
        setData(data.filter(i => i.id === +id)[0] );
    }
    const onRequest = () => {

        switch (type) {
            case 'armchairs':
                getArmchairs().then(onLoaded);
                break;
            case 'tables':
                getTables().then(onLoaded);
                break;
            case 'accessories':
                getAccess().then(onLoaded);
                break;
            default: 
            throw new Error('unexpected error');
        }   
    }

    useEffect(() => {onRequest()}, [id, type])



    useEffect(()=> {
        if(id == 'undefined'){
            navigate("/notfound", { replace: true });
        }
    }, [id, type, navigate]);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    const saveToStorage = (id, count) => {
        setShowBag(true); 

        if (!localStorage.getItem('goods') ){
            localStorage.setItem("goods", JSON.stringify([ {[`${id}`]: 1}]));
            setTotalPrice([{[`${id}`]: 1}])
        }
        else if (localStorage.getItem('goods') &&  [...JSON.parse(localStorage.getItem('goods'))].map((item=>Object.keys(item)[0].toString())).includes(id.toString())) {
        }
        else {
            localStorage.setItem("goods", JSON.stringify([...JSON.parse(localStorage.getItem('goods')), {[`${id}`]: 1}]));
            setTotalPrice([...totalPrice, {[`${id}`]: 1}])
        }
    }


    const outStock = data?.outStock? 'outStock' : null;

    return (
        <>
            <Helmet>
                <meta name="keywords" content="меблі, дерево, крісла, підставки, магазин меблів, каталог, столи, мʼякі крісла, аксесуари, крісло, мʼяке крісло, підставка, тримач, стіл, маленький стіл, журнальний столик, журнальні столи, журнальні столики, журнальний стіл, каталог, hvoja, Hvoja"/>
                <meta property="og:title" content="Hvoja - інтернет магазин меблів" />
                {/* <meta property="og:image" content='../../../img/backgrounds/main-page-bckg.png' /> */}
                <meta property="og:url" content="https://hvoja.store/" />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Hvoja - інтернет магазин меблів" />
                <meta name="twitter:description" content="Даруємо затишок та комфорт." />
                <meta name="twitter:image" content="../../../img/backgrounds/main-page-bckg.png" />

                <meta name="description" content={data?.descr} />
                <meta property="og:title" content={data?.name} />
                <meta property="og:description" content={data?.descr} />
                <meta property="og:image" content={data?.imgArr} />
                <meta property="product:price:amount" content={data?.price} />
                <meta property="product:price:currency" content="UAH" />
                <meta name="robots" content="index, follow"/>

                <title>{data?.name}</title>
            </Helmet>

            <div className={`prod_page_container ${outStock}`}>

                <Modal show={show} onHide={handleClose} size='lg'>
                    <Slider item={data} type={type} id={id}/>
                </Modal>



                <div className="prod_back_link">
                    <Link to={`/catalog/${type}`}>← Назад до каталогу</Link>
                </div>

                {loading? 

                    <div style={{textAlign: 'center'}}>
                        <Spinner animation="border" role="status" >
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                        : 
                        
                        error? <Error/> :

                        !data?
                        <ErrorNoItem/>
                        :

                    <div className="prod_container">
                        <div className="prod_img" >
                            <Slider item={data} type={type} id={id} handleShow={handleShow}/>
                        </div>

                        {data?.delivery? <Delivery/> : null}

                        <div className="prod_info">
                            <div className="header">
                                <h3>{data?.name}</h3>
                                <p style={{fontStyle: 'italic'}}>{data?.descr}</p>
                                {data?.char?.map((item ,i) => {
                                    return <p key={i}>{item}</p>
                                })}
                                <p><b>Виміри:</b> {data?.size}</p>
                            </div>
                            
                            <div className="price">
                                {data?.discount ? 
                                    <div className="old">
                                    <p className="old_price">{`${data?.price}  ₴`}</p>
                                    <p className="new_price">{`${data?.discountPrice}  ₴`}</p>
                                    </div>
                                    :
                                    <p>{`${data?.price}  ₴`}</p>
                                }

                                {
                                    data?.outStock?
                                    <div className="outStock_icon">немає в наявності</div>
                                    :
                                    <CustomButton action={()=>saveToStorage(data?.guid)} text="додати в кошик"/>
                                }
                                
                            </div>
                        </div>   
                    </div>
                }
            </div>

        </>
    )
}

export default SingleProductPage;